<template>
  <div class="goodNews">
    <HeadView classA="3" headBg="1"></HeadView>
    <div class="main">
      <h2>用户心声|电子化管理让诊所走的更长远</h2>
      <p class="time">2019-10-17</p>
      <p>
        <span class="indent0"
          >虽然只是小小的诊所，作为诊所的负责人，在管理时也难免劳心伤神。如何让患者就医时更舒心，关系着诊所能否走的长远。</span
        >
        <span class="indent0"
          >想要诊所发展壮大，并能轻松管理，电子化的管理系统是必不可缺的。云南昆明的周灵大夫，在2016年就意识到了这个问题。</span
        >
      </p>
      <img src="../../assets/images/newsDetail/userVoice11/n1.png" alt />
      <h4>1、云屏解决了复诊插号的冲突</h4>
      <p>
        <span
          >16年以前我们病人比较多，那时候患者来了排队，早上有时候很早就来排了，都排到门的外面去，还会因为有的人来复诊、插号，都会有冲突。</span
        >
        <span
          >来一个家属她要抱着小孩子，还要在那里排队排几个小时才能看到。</span
        >
        <span
          >为了解决这个问题，16年我去外面学习考察，看到这个致医云诊室就非常喜欢，很快就把它引进了。</span
        >
        <span>来了以后最好的一个地方就是，微信挂号扫码挂号。</span>
        <span
          >我挂了云屏在那里，患者进来以后他就自然去扫码，可以告诉他在几号，你前面还有多少人在等待。</span
        >
        <span
          >他可以带着小孩子在周围转一圈，手机上会有提醒，快要到你的就诊时间了，你再进来就可以。</span
        >
        <span>免去了很多排队的，一大堆人在这里排长龙的这个状况。</span>
      </p>
      <h4>2、云诊室记住了两年的患者</h4>
      <p>
        <span
          >我们虽然作为一个诊所，但是为了更好的管理，电子化的管理是非常需要的。</span
        >
        <span
          >16年我到外学习的时候，就去考察了很多，包括电子化管理，很需要一个像医院里面那种，挂号就诊排号，给患者一个更好的环境。</span
        >
        <span
          >我16年就引进了这个电子化的管理，就是我们现在用的这个致医云诊室，我觉得其实挺好的。</span
        >
        <span
          >病人进来以后他所有的记录，包括他的姓名、家庭地址、家属、电话号码，全都有记录。</span
        >
        <span
          >每一天的病例，他来以后就诊当天是什么情况，给的什么治疗，做的执行的情况是什么，第二天来复诊的时候一调出来就能看到。</span
        >
        <span
          >他过了几个月甚至是一两年再就诊的时候，我都能看到他的病例，对他的健康有一个大体的评价。</span
        >
      </p>

      <h4>3、我和患者成为了朋友</h4>
      <p>
        <span
          >这个很好的一个地方就是，就诊以后我开的口服药，它还有提醒，比如说我开了一个什么药，一天三次或者一天两次，它到时间段就会发消息提醒他，几点几点服药或者是需要做什么复诊这些，我觉得这个非常好。</span
        >
        <span
          >患者还可以在上面评价我们，评价我们的环境服务态度，我也可以看可以改进。我觉得很多患者到我们这里以后，第一能够认可我们的绿色疗法，第二觉得我们的就医环境比较好，比较方便快捷。</span
        >
        <span>很多都和我们成了很好的朋友，现在这么几年的时间。</span>
      </p>
    </div>
    <Foot></Foot>
  </div>
</template>
<style scoped>
@import "../../assets/style/goodNews.css";
</style>
<script>
import Foot from "@/components/common/Foot";
import HeadView from "@/components/common/Head";

export default {
  name: "news",
  components: {
    HeadView,
    Foot,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
